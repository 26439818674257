import { NgModule } from '@angular/core';
import { InstagramComponent } from './instagram.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { Ae2InstagramModule } from '@angularecommerce/core/components/instagram/instagram.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2InstagramModule
  ],
  declarations: [InstagramComponent],
  exports: [InstagramComponent]
})
export class InstagramModule { }

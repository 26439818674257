<ae2-activity-list-container showInActivitiesPage="true" active="true">
    <ng-template let-results="results">
        <ng-container *ngIf="results.length > 0">
            <section class="activies">
                <div class="activies-container">
                    <div class="activies-title">ATIVIDADES</div>
                    <!-- <div fxLayout fxLayoutAlign="center center"> -->
                    <div class="activies-boxImages">
                        <ng-container *ngFor="let activity of results">
                            <!-- <div fxFlex="{{(100 / results.length)}}%" class="activies-container-box"> -->
                            <div class="activies-container-box">
                                <!-- <div fxFlex="auto" class="activies-container-box"> -->
                                <div class="activies-container-box-name">
                                    <div class="activies-container-box-name-title">{{activity?.name}}</div>
                                </div>
                                <div class="activies-container-box-content">
                                    <div class="activies-container-box-content-description" [innerHTML]="activity?.description"></div>
                                    <div>
                                        <div class="activies-container-box-content-title">INSTRUTORES</div>
                                        <ng-container *ngIf="activity?.id">
                                            <ae2-instructor-list-container [activityList]="[activity?.id]" [active]="true"
                                                [showInInstructorsPage]="true">
                                                <ng-template #instructorListTemplate let-results="results">
                                                    <div fxLayout="row wrap" fxLayoutGap="20px">
                                                        <ng-container *ngFor="let item of results">
                                                            <div>
                                                                <img class="activies-container-box-content-image" src="{{item?.smallImage ? (item?.smallImage | ae2Thumbor:'80x') : '/assets/images/fallback.jpg'}}"
                                                                    alt="{{item?.nickname || item?.firstName}}">
                                                                <div class="activies-container-box-content-instructorName">{{item?.nickname || item?.firstName}}</div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-template>
                                            </ae2-instructor-list-container>
                                        </ng-container>
                                    </div>
                                    <div class="activies-container-box-content-button">
                                        <button mat-stroked-button routerLink="/agenda" [queryParams]="{activities: activity?.id}">AGENDAR</button>
                                    </div>
                                </div>
                                <div class="activies-container-box-image" [ngStyle]="{'background-image': 'url(' + (activity?.mediumImage | ae2Thumbor:'280x') || '/assets/images/fallback.jpg' + ')'}"></div>
                                <!-- <ng-container *ngIf="activity?.mediumImage">
                                    <div class="activies-container-box-image" [style.background-image]="'url(' + ((activity?.mediumImage | ae2Thumbor:'280x') | ae2SafeStyle) + ')'"></div>
                                </ng-container>
                                <ng-container *ngIf="!activity?.mediumImage">
                                    <div class="activies-container-box-image activies-container-box-image-noImage"></div>
                                </ng-container> -->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </section>
        </ng-container>
    </ng-template>
</ae2-activity-list-container>
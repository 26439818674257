import { Component, OnInit } from '@angular/core';
import {  Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  carouselConfig: Ae2CarouselConfig;
  open: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    // const bannerSize = {
    //   xs: '375x',
    //   sm: '1024x',
    //   md: '1280x',
    //   lg: '1920x'
    // };
    // const reatios = {
    //   xs: '16:9',
    //   sm: '4:3',
    //   md: '8:5',
    //   lg: '16:9'
    // };
    this.carouselConfig = new Ae2CarouselConfig();
    this.carouselConfig.useDottedOverLay = false;
    this.carouselConfig.intervalTime = 5000;
    // this.carouselConfig.bannerSize = bannerSize;
    // this.carouselConfig.ratios = reatios;

  }
  openDialog(): void {
    this.open = true;
    setTimeout(() => {
      this.open = false;
    }, 100);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    '[class.footer]': 'true'
  }
})
export class FooterComponent {
  form: FormGroup;

  constructor(
    private marketingService: Ae2MarketingService,
    private feedbackService: Ae2FeedbackService,
  ) {
    this.form = this.createForm();
  }

  submit(event: any): void {
    if (this.form.get('email').value === '') {
      this.notifyError(
        `Oops, não foi possível assinar a newsletter`
      );
      return;
    }

    const data = new Ae2MarketingEmailsAcquired();
    data.email = this.form.get('email').value;
    data.source = 'https://' + window.location.host + '/';
    data.campaign = 'newslleter';

    this.marketingService.subscribeUser(data).subscribe((res) => {
      if (res.email) {
        this.form.reset();
        this.notifySuccess(
          `Newsletter assinada com sucesso`
        );
      } else {
        this.notifyError(
          `Oops, não foi possível assinar a newsletter`
        );
      }
    }, (error) => {
      this.notifyError(
        `Oops, não foi possível assinar a newsletter`
      );
    });
  }

  targetLint(link: string): void {
    window.open(link, '_blank');
  }

  protected notifySuccess(msg: any): void {
    this.feedbackService.success({
      message: msg,
      format: Ae2FeedbackFormat.Notification,
      duration: 2000
    });
  }

  protected notifyError(error: any): void {
    this.feedbackService.error({
      message: error,
      format: Ae2FeedbackFormat.Notification,
      duration: 2000
    });
  }

  protected createForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
    });
  }



}

<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <!-- <ng-template ae2CarouselOverlayContent>
        <div class="buttons">
            <div routerLink="/pacotes" class="buttons-button">
                <h2 class="buttons-button-title">COMPRAR CRÉDITOS</h2>
                <div class="buttons-button-hidden">
                    <p class="buttons-button-text">Conheça nossos pacotes.</p>
                </div>
            </div> 
            <div routerLink="/agenda" class="buttons-button">
                <h2 class="buttons-button-title">AGENDA</h2>
                <div class="buttons-button-hidden">
                    <p class="buttons-button-text">Reserve seu horário.</p>
                </div>
            </div>
        </div>
    </ng-template> -->
</ae2-carousel-container>
<div class="bg">
    <app-package></app-package>
    <app-activities></app-activities>
    <!-- <app-trainers></app-trainers> -->
    <app-instagram></app-instagram>
</div>
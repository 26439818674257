<section class="instagram">
    <div fxLayout fxLayoutAlign="center center" class="instagram-title">
        <div class="instagram-title-icon">
            <i class="fa fa-instagram" aria-hidden="true"></i>
        </div>
        @EUSOULRIDE
    </div>
    <div>
        <ae2-instagram [mode]="0" [userId]="5515978848" [accessToken]="'5515978848.744d4d8.accdf4ac29aa4a1eb7dedbd40005b2fd'"></ae2-instagram>
    </div>
</section>
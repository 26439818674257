import { Component, OnInit } from '@angular/core';
import { Ae2LocalizeService } from '@angularecommerce/core/services/localize';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  
  instrutor: any;

  constructor(private ae2LocalizeService: Ae2LocalizeService) {

  }
  ngOnInit(): void {
  }

  numberFormat(num: string): string {
    return this.ae2LocalizeService.decimalFormatter(num, {
      digits: '2.0-0'
    });
  }

  toCurrency(value: any): string {
    return this.ae2LocalizeService.currencyFormatter(value);
  }



}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  // {
  //   path: 'login',
  //   loadChildren: 'app/features/login/login.module#LoginModule',
  //   data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  // },
  {
    path: 'online/class',
    loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
  },
  {
    path: 'padroes-seguranca',
    loadChildren: 'app/features/seguranca/seguranca.module#SegurancaModule'
  },

  {
    path: 'online/thank-you',
    loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
  },

  {
    path: 'online/already-connected',
    loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'termos-e-condicoes',
    loadChildren: 'app/features/termos/termos.module#TermosModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: 'app/features/policy/policy.module#PolicyModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'manifesto',
    loadChildren: 'app/features/manifest/manifest.module#ManifestModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slugatividade/:slugevento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'contato',
    loadChildren: 'app/features/contact-page/contact-page.module#ContactPageModule',
    data: { someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<section class="pack">
    <div class="pack-container" fxLayout fxLayout.xs="column" fxLayoutGap.sm="30px" fxLayoutGap="68px"
        fxLayoutGap.xs="20px">
        <div class="pack-container-text" fxFlex="35%" fxFlex.sm="35%" fxFlex.xs="auto">
            <h2 class="pack-container-title">NOSSOS PLANOS</h2>
            <p class="pack-container-desc">Soul Ride 2.0: Muito mais do que um treino em casa</p>
            <p class="pack-container-desc">Além das aulas de bike presenciais que vocês já conhecem, apresentamos novas
                modalidades de aulas online, para você treinar onde quer que esteja:</p>
            <p class="pack-container-desc">Mat Pilates, B.A.L.A, Yoga, BUUM, Aero Fight, Step e mais. Além das nossas
                tradicionais aulas de Bike, que agora serão transmitidas online também.</p>
            <p class="pack-container-desc">E não pára por aí. Em parceria com a ONG Construide, a cada aula online
                vendida, a Soul Ride irá doar 1 prato de comida. Ao fazer aulas online, você estará automaticamente
                ajudando a colocar comida na mesa de alguém que precisa. Para agendar suas aulas você precisa ter
                créditos. Para comprar créditos escolha um dos nossos pacotes. Planos transferíveis e compartilháveis
                permitem que você transfira créditos permanentemente para amigos e/ou permite que mais de uma pessoa
                consuma seus créditos.</p>

        </div>
        <div fxFlex="calc(65% - 68px)" fxFlex.sm="calc(65% - 30px)" fxFlex.xs="auto">

            <ae2-category-list-container [showOnWebsite]="true">
                <ng-template let-categories="results">
                    <ng-container *ngFor="let category of categories">

                        <div fxLayout fxLayoutAlign="center center">
                            <h3>{{ category.name }}</h3>
                        </div>

                        <ae2-package-list-container [categoryList]="[category.id]">
                            <ng-template #packageListTemplate let-packages="results">
                                <ng-container class="package" *ngIf="packages.length > 0">

                                    <div>
                                        <ng-container *ngFor="let package of packages">
                                            <ae2-package-widget [data]="package">
                                                <ng-template #defaultTemplate>
                                                    <div class="pack-container-box" fxLayout fxLayout.xs="column">
                                                        <div class="pack-container-box-left" fxFlex.xs="auto"
                                                            fxFlex="75%">
                                                            <div class="pack-container-box-lef-title">
                                                                {{package?.name}}
                                                                <ng-container
                                                                    *ngIf="package?.currentEventCreditQuantity > 1">
                                                                    //
                                                                    <ng-container
                                                                        *ngIf="package?.eventCreditIsUnlimited; else isNotUnlimited">
                                                                        R$ {{numberFormat(package?.currentPrice /
                                                            package?.currentEventCreditQuantity)}}* / AULA
                                                                    </ng-container>

                                                                    <ng-template #isNotUnlimited>
                                                                        R$ {{numberFormat(package?.currentPrice /
                                                            package?.currentEventCreditQuantity)}} / AULA
                                                                    </ng-template>
                                                                </ng-container>
                                                            </div>
                                                            <div class="pack-container-box-lef-info"><b>
                                                                    <ae2-pw-card-validity></ae2-pw-card-validity>
                                                                </b> //
                                                                <ae2-pw-card-share-info></ae2-pw-card-share-info>
                                                            </div>
                                                            <ng-container *ngIf="package?.eventCreditIsUnlimited">
                                                                <div class="pack-container-box-lef-info">* Caso você
                                                                    faça
                                                                    {{(package?.currentEventCreditQuantity / (package?.currentEventCreditValidity / 30)).toFixed()}}
                                                                    aulas
                                                                    no mês</div>
                                                            </ng-container>
                                                            <ng-container *ngIf="package?.tagline">
                                                                <div class="pack-container-box-lef-info">
                                                                    *{{package?.tagline}}</div>
                                                            </ng-container>
                                                            <ng-container *ngIf="package?.installmentsMaxQuantity > 1">
                                                                <div class="pack-container-box-lef-info">* R$
                                                                    {{numberFormat(package?.currentPrice)}} Parcelável
                                                                    em até
                                                                    {{package?.installmentsInfo?.creditcard?.installments[package?.installmentsMaxQuantity
                                                        - 1]?.description}}</div>
                                                            </ng-container>
                                                        </div>
                                                        <div [ae2AddToCheckout]="package.id" [rules]="package.rules"
                                                            [ae2AddAnalytics]="package"
                                                            [attr.product-slug]="package.slug"
                                                            class="pack-container-box-right" fxFlex.xs="auto"
                                                            fxFlex="25%" fxLayout="column"
                                                            fxLayoutAlign="center center">
                                                            <div class="pack-container-box-right-price">
                                                                <ng-container
                                                                    *ngIf="package?.priceSalesBase <= package?.currentPrice">
                                                                    <!-- <ae2-pw-card-amount></ae2-pw-card-amount> -->
                                                                    <div class="pack-container-box-price-no-discount">
                                                                        {{toCurrency(package?.currentPrice)}}</div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="package?.priceSalesBase > package?.currentPrice">
                                                                    <div fxLayout="column">
                                                                        <span>De <span
                                                                                class="ae2-pkg-content_total--base">{{toCurrency(package?.priceSalesBase)}}</span></span>
                                                                        <span class="ae2-pkg-content_total">Por
                                                                            {{toCurrency(package?.currentPrice)}}</span>
                                                                    </div>
                                                                </ng-container>
                                                                <!-- <ae2-pw-card-amount></ae2-pw-card-amount> -->
                                                            </div>
                                                            <div class="pack-container-box-right-buy">COMPRAR</div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ae2-package-widget>
                                        </ng-container>
                                    </div>

                                </ng-container>
                            </ng-template>
                        </ae2-package-list-container>

                    </ng-container>
                </ng-template>
            </ae2-category-list-container>
        </div>
    </div>
</section>
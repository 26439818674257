import { Component, OnInit } from '@angular/core';
import {
    Ae2HeaderWidgetConfig, Ae2HeaderLinkToSession, Ae2HeaderLinkToPage,
    Ae2HeaderSubmenu, Ae2HeaderMenu
} from '@angularecommerce/core/components/header-widget';
import { ObservableMedia } from '@angular/flex-layout';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();

    constructor(
        private mq: ObservableMedia
    ) { }

    ngOnInit(): void {
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            // new Ae2HeaderSubmenu('O ESTÚDIO', [
            //     new Ae2HeaderLinkToPage('MANIFESTO', '/manifesto'),
            //     new Ae2HeaderLinkToPage('SOBRE', '/sobre'),
            //     new Ae2HeaderLinkToPage('PRÓXIMOS EVENTOS', '/proximos-eventos'),
            //     new Ae2HeaderLinkToPage('INSTRUTORES', '/instrutores'),
            // ]),
            new Ae2HeaderLinkToPage('COVID19', '/padroes-seguranca'),
            new Ae2HeaderLinkToPage('MANIFESTO', '/manifesto'),
            new Ae2HeaderLinkToPage('PACOTES', '/pacotes'),
            new Ae2HeaderLinkToPage('AGENDA', '/agenda'),
            new Ae2HeaderLinkToPage('CONTATO', '/contato'),
        ];
        this.headerConfig.backgroundColor = '#ffffff';
        this.headerConfig.transparentOnRoutes = ['', 'contato', 'agenda', 'manifesto'];
        this.headerConfig.showPhone = true;
    }

}

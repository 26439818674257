/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instagram.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../node_modules/@angularecommerce/core/components/instagram/instagram.component.ngfactory";
import * as i5 from "@angularecommerce/core/components/instagram/instagram.component";
import * as i6 from "@angularecommerce/core/services/api-instagram/api-instagram.service";
import * as i7 from "./instagram.component";
var styles_InstagramComponent = [i0.styles];
var RenderType_InstagramComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstagramComponent, data: {} });
export { RenderType_InstagramComponent as RenderType_InstagramComponent };
export function View_InstagramComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["class", "instagram"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "instagram-title"], ["fxLayout", ""], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(3, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "instagram-title-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-instagram"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" @EUSOULRIDE "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "ae2-instagram", [], [[2, "ae2-instagram", null]], null, null, i4.View_Ae2InstagramComponent_0, i4.RenderType_Ae2InstagramComponent)), i1.ɵdid(9, 114688, null, 2, i5.Ae2InstagramComponent, [i6.Ae2ApiInstagramService], { mode: [0, "mode"], userId: [1, "userId"], accessToken: [2, "accessToken"] }, null), i1.ɵqud(335544320, 1, { templateInstagramGrid: 0 }), i1.ɵqud(335544320, 2, { templateInstagramSlider: 0 })], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 3, 0, currVal_1); var currVal_3 = 0; var currVal_4 = 5515978848; var currVal_5 = "5515978848.744d4d8.accdf4ac29aa4a1eb7dedbd40005b2fd"; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_2 = true; _ck(_v, 8, 0, currVal_2); }); }
export function View_InstagramComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instagram", [], null, null, null, View_InstagramComponent_0, RenderType_InstagramComponent)), i1.ɵdid(1, 114688, null, 0, i7.InstagramComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstagramComponentNgFactory = i1.ɵccf("app-instagram", i7.InstagramComponent, View_InstagramComponent_Host_0, {}, {}, []);
export { InstagramComponentNgFactory as InstagramComponentNgFactory };

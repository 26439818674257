<ae2-site-settings>
    <ng-template ae2SiteSettingsContent let-site="site">
        <footer>
            <section class="footer">
                <div class="footer-links" fxLayout fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="100px"
                    fxLayoutGap.xs="15px">
                    <div class="footer-links-item-skew"><a class="footer-links-item" routerLink="/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</a></div>
                    <div class="footer-links-item-skew"><a class="footer-links-item" taget="_blank" href="/media/terms-and-conditions/termos-e-condicoes_pmgofqm.pdf">TERMOS E CONDIÇÕES</a></div>
                </div>
                <div fxLayoutAlign="center center">
                    <div class="footer-brand"></div>
                </div>
                <div fxLayoutAlign="center center">
                    <p class="footer-tagline" [innerHTML]="site?.tagLine"></p>
                </div>
                <div class="footer-social" fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
                    <div *ngIf="site.socialTwitter" (click)="targetLint(site.socialTwitter)">
                        <i class="iconFooter fa fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialInstagram" (click)="targetLint(site.socialInstagram)">
                        <i class="iconFooter fa fa-instagram" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialFacebook" (click)="targetLint(site.socialFacebook)">
                        <i class="iconFooter fa fa-facebook" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialSpotify" (click)="targetLint(site.socialSpotify)">
                        <i class="iconFooter fa fa-spotify" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialYoutube" (click)="targetLint(site.socialYoutube)">
                        <i class="iconFooter fa fa-youtube" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialLinkedin" (click)="targetLint(site.socialLinkedin)">
                        <i class="iconFooter fa fa-linkedin" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialVimeo" (click)="targetLint(site.socialVimeo)">
                        <i class="iconFooter fa fa-vimeo" aria-hidden="true"></i>
                    </div>
                    <div *ngIf="site.socialFlickr" (click)="targetLint(site.socialFlickr)">
                        <i class="iconFooter fa fa-flickr" aria-hidden="true"></i>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <p class="footer-tagline">Fique por dentro! Assine nossa newsletter:</p>
                    <form class="footer-newsletter_form" [formGroup]="form" (submit)="submit($event)" fxLayout>
                        <input class="footer-newsletter_for-input" placeholder="Digite aqui seu e-mail" formControlName="email">
                        <button mat-raised-button class="footer-newsletter_for-button" [disabled]="form.pending || form.invalid">
                            ENVIAR
                        </button>
                    </form>
                </div>
            </section>
            <section class="footer-copy">
                <div class="footer-copy-content" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <div class="footer-copy-content-text">Copyright © 2018. Todos os direitos reservados.</div>
                    <div>
                        <a class="footer-copy-content-link" href="https://angulare.app/?utm_source=cws&utm_campaign=soulride" target="_blank">Powered
                            by Angular e-Commerce</a>
                    </div>
                </div>
            </section>
        </footer>
    </ng-template>
</ae2-site-settings>
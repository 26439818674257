import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings/site-settings.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    FlexLayoutModule,
    Ae2SiteSettingsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }

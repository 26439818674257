/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activities.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@angularecommerce/core/components/instructor-list-container/instructor-list-container.component.ngfactory";
import * as i7 from "@angularecommerce/core/classes/list-container-base/list-container-base";
import * as i8 from "@angularecommerce/core/components/instructor-list-container/instructor-list-container.component";
import * as i9 from "@angularecommerce/core/services/instructors/instructor.service";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "@angular/router";
import * as i16 from "@angularecommerce/core/pipes/thumbor/thumbor.pipe";
import * as i17 from "@angularecommerce/core/services/settings/settings.service";
import * as i18 from "../../../../../node_modules/@angularecommerce/core/components/activity-list-container/activity-list-container.component.ngfactory";
import * as i19 from "@angularecommerce/core/components/activity-list-container/activity-list-container.component";
import * as i20 from "@angularecommerce/core/services/activities/activities.service";
import * as i21 from "./activities.component";
import * as i22 from "@angular/platform-browser";
var styles_ActivitiesComponent = [i0.styles];
var RenderType_ActivitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivitiesComponent, data: {} });
export { RenderType_ActivitiesComponent as RenderType_ActivitiesComponent };
function View_ActivitiesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "img", [["class", "activies-container-box-content-image"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "activies-container-box-content-instructorName"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (((_v.context.$implicit == null) ? null : _v.context.$implicit.smallImage) ? i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.smallImage), "80x")) : "/assets/images/fallback.jpg"), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", (((_v.context.$implicit == null) ? null : _v.context.$implicit.nickname) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.firstName)), ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.nickname) || ((_v.context.$implicit == null) ? null : _v.context.$implicit.firstName)); _ck(_v, 5, 0, currVal_2); }); }
function View_ActivitiesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row wrap"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 1785856, null, 0, i2.LayoutGapDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i1.NgZone, i4.Directionality, i3.StyleUtils], { gap: [0, "gap"] }, null), (_l()(), i1.ɵand(16777216, [[2, 2], [1, 2]], null, 1, null, View_ActivitiesComponent_6)), i1.ɵdid(4, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "20px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.results; _ck(_v, 4, 0, currVal_2); }, null); }
function View_ActivitiesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ae2-instructor-list-container", [], null, null, null, i6.View_Ae2InstructorListContainerComponent_0, i6.RenderType_Ae2InstructorListContainerComponent)), i1.ɵprd(6144, null, i7.Ae2ListContainerBase, null, [i8.Ae2InstructorListContainerComponent]), i1.ɵdid(3, 4833280, null, 1, i8.Ae2InstructorListContainerComponent, [i9.Ae2InstructorService, i1.ChangeDetectorRef], { active: [0, "active"], activityList: [1, "activityList"], showInInstructorsPage: [2, "showInInstructorsPage"] }, null), i1.ɵqud(603979776, 2, { templateRef: 0 }), i1.ɵpad(5, 1), (_l()(), i1.ɵand(0, [[2, 2], [1, 2], ["instructorListTemplate", 2]], null, 0, null, View_ActivitiesComponent_5))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = _ck(_v, 5, 0, ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)); var currVal_2 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ActivitiesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "activies-container-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "activies-container-box-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "activies-container-box-name-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "activies-container-box-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "activies-container-box-content-description"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "activies-container-box-content-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["INSTRUTORES"])), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_ActivitiesComponent_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "activies-container-box-content-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "button", [["mat-stroked-button", ""], ["routerLink", "/agenda"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 16384, null, 0, i15.RouterLink, [i15.Router, i15.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(16, { activities: 0 }), (_l()(), i1.ɵted(-1, 0, ["AGENDAR"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "activies-container-box-image"]], null, null, null, null, null)), i1.ɵdid(19, 278528, null, 0, i5.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵppd(20, 2), i1.ɵpod(21, { "background-image": 0 })], function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); _ck(_v, 11, 0, currVal_2); var currVal_5 = _ck(_v, 16, 0, ((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_6 = "/agenda"; _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 21, 0, (("url(" + i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.mediumImage), "280x"))) || ("/assets/images/fallback.jpg" + ")"))); _ck(_v, 19, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 6, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 14).disabled || null); var currVal_4 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_3, currVal_4); }); }
function View_ActivitiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "section", [["class", "activies"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "activies-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "activies-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ATIVIDADES"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "activies-boxImages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_ActivitiesComponent_3)), i1.ɵdid(7, 802816, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.results; _ck(_v, 7, 0, currVal_0); }, null); }
function View_ActivitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_ActivitiesComponent_2)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.results.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ActivitiesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i16.Ae2ThumborPipe, [i17.Ae2SettingsService]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ae2-activity-list-container", [["active", "true"], ["showInActivitiesPage", "true"]], null, null, null, i18.View_Ae2ActivityListContainerComponent_0, i18.RenderType_Ae2ActivityListContainerComponent)), i1.ɵprd(6144, null, i7.Ae2ListContainerBase, null, [i19.Ae2ActivityListContainerComponent]), i1.ɵdid(3, 4833280, null, 1, i19.Ae2ActivityListContainerComponent, [i20.Ae2ActivitiesService, i1.ChangeDetectorRef], { active: [0, "active"], showInActivitiesPage: [1, "showInActivitiesPage"] }, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_ActivitiesComponent_1))], function (_ck, _v) { var currVal_0 = "true"; var currVal_1 = "true"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ActivitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activities", [], null, null, null, View_ActivitiesComponent_0, RenderType_ActivitiesComponent)), i1.ɵdid(1, 114688, null, 0, i21.ActivitiesComponent, [i22.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivitiesComponentNgFactory = i1.ɵccf("app-activities", i21.ActivitiesComponent, View_ActivitiesComponent_Host_0, {}, {}, []);
export { ActivitiesComponentNgFactory as ActivitiesComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { MatButtonModule } from '@angular/material';
import { ActivitiesModule } from '../../shared/components/activities/activities.module';
import { TrainersModule } from '../../shared/components/trainers/trainers.module';
import { PackageModule } from '../../shared/components/package/package.module';
import { ContactModule } from '../../shared/components/contact/contact.module';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InstagramModule } from '../../shared/components/instagram/instagram.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    Ae2CarouselContainerModule,
    ActivitiesModule,
    TrainersModule,
    PackageModule,
    ContactModule,
    RouterModule,
    FlexLayoutModule,
    InstagramModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
